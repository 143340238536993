import React, { useState, useEffect } from 'react';
import dictionaryJson from './includes/dictionary.json'; 
import ScoreKeeper from './scrabblecopilot/ScoreKeeper';
import DictionaryCheck from './scrabblecopilot/DictionaryCheck'; 
import WordBuilder from './scrabblecopilot/WordBuilder'; 
import AddOneLetter from './scrabblecopilot/AddOneLetter'; 
import WordLists from './scrabblecopilot/WordLists'; 
import { letterValues } from './includes/ScrabbleValues';

function ScrabbleCopilot() {
    const [dictionary, setDictionary] = useState([]);
    const sections = ['Score Keeper', 'Dictionary Check', 'Word Builder', 'Add One Letter', 'Word Lists'];

    useEffect(() => {
        // Set the dictionary state with the imported JSON data
        setDictionary(dictionaryJson);

        // Cleanup function to clear any resources
        return () => {
            // Perform cleanup tasks here (if any)
        };
      }, []); 


    return (
        <div>
            {sections.map((section, index) => {
                // Dynamically retrieve the component based on the section name
                let Component;
                switch(section) {
                    case 'Score Keeper':
                        Component = ScoreKeeper;
                        break;
                    case 'Dictionary Check':
                        Component = DictionaryCheck;
                        break;
                    case 'Word Builder':
                        Component = WordBuilder;
                        break;
                    case 'Add One Letter':
                        Component = AddOneLetter;
                        break;
                    case 'Word Lists':
                        Component = WordLists;
                        break;
                }
                return (
                    <div key={index} style={{marginBottom:'4px'}}>
                        <Component dictionary={dictionary} letterValues={letterValues} />
                    </div>
                );
            })}
        </div>
    );
  }
  
  export default ScrabbleCopilot;