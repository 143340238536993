import React, { useState, useRef, forwardRef } from 'react';
import Tile from '../Tile';
import HiddenTextInput from '../HiddenTextInput';

const DictionaryCheck = forwardRef(({ dictionary, letterValues }, ref) => {
    const [isActive, setIsActive] = useState(false);
    const [isMatch, setIsMatch] = useState(false);
    const [word, setWord] = useState('');
    const [enterPressed, setEnterPressed] = useState(false);

    // focus on keyboard when module renders
    const inputRef = useRef(null);

    const [tiles, setTiles] = useState([]);

    const handleKeyDown = (event) => {
        const key = event.key.toUpperCase(); // Convert to uppercase for consistency
        if (/^[A-Z ]$/.test(key) && tiles.length < 8) { // Check if key pressed is a letter
            setEnterPressed(false);
            setTiles([...tiles, key]);
        } else if (key === 'BACKSPACE') {
            setEnterPressed(false);
            setTiles(tiles.slice(0, -1));
        } else if (key === 'ENTER') {
            setEnterPressed(true);
            checkWord();
        }
    };

    function checkWord() {
        setWord(tiles.join('').toLowerCase());

        if (dictionary.includes(tiles.join('').toLowerCase())) {
            setIsMatch(true);
        }
        else {
            setIsMatch(false);
        }
    }

    const open = () => {
        setIsActive(true);
        inputRef.current?.focus();
    };

    const close = () => {
        setIsActive(false);
    };

    return (
        <div onClick={() => inputRef.current?.focus()} onKeyDown={handleKeyDown}>
            <div className="container" onClick={isActive ? close : open}>
                Dictionary Check
                {isActive ?
                    <div className="plusminus">&#x2212;</div>
                    :
                    <div className="plusminus">&#x2b;</div>
                }
            </div>
            
            <div id='scrabble-content' className={isActive ? 'active' : ''}>
                <div>Type a word and press 'Enter' to check its validity.</div>

                {tiles.map((tile, index) => (
                    <Tile key={index} letter={tile} value={letterValues[tile]} color='green' />
                ))}

                <HiddenTextInput inputRef={inputRef} />

                {enterPressed &&
                    (isMatch ? (
                        <div style={{color:'darkgreen'}}><strong>{word}</strong> is a valid word</div>
                    ) : (
                        word.length > 0 && (
                            <div style={{color:'red'}}><strong>{word}</strong> is not valid</div>
                        )
                    )
                    )
                }
            </div>
        </div>
    );
});

export default DictionaryCheck;
