import './tile.css';

const Tile = ({ letter, value, color }) => {
    const className = `tile ${color || 'gray'}`;
    const points = value === -1 ? "" : value;

    return (
      <div className={className}>
        {letter}
        <span className="subscript">{points}</span>
       </div>
    );
}
  
export default Tile;