import React, {useRef, useEffect} from 'react';
import './share.css';

const Share = (props) => {
    const refMessage = useRef(null);

    const clickShare = () => {
        console.log(props.shareMessage);

        navigator.clipboard.writeText(props.shareMessage).then(function() {  
            refMessage.current.style.display = 'block';
        });

        // Check if navigator.share is supported by the browser
        if (navigator.share && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            navigator.share({
                title: props.shareTitle,
                text: props.shareMessage
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } 
    };

    return (
        <div>
            <div ref={refMessage} style={{ display: 'none' }}>
                Results copied to clipboard.<br />
                Paste on social media to challenge friends!
            </div>
            <button onClick={clickShare} className={'shareBtn'}>Share Score</button>
        </div>
    );
}
  
export default Share;