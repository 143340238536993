import React, {} from 'react';
import Tile from '../Tile.js';

const Guess3Rack = (props) => {
    const underscoreTiles = [];
    //console.log(props.word);

    for (let i = props.hint.length; i < props.word.length - props.guess.length; i++) {
        underscoreTiles.push(<Tile key={`underscore-${i}`} letter='_' color='blank' />);
    }

    return (
        <div id="rack">
            {props.hint.split('').map((tile, index) => (
                <Tile key={index} letter={tile} color='green' /> 
            ))}
            {props.guess.split('').map((tile, index) => (
                <Tile key={index} letter={tile} color='gray' />
            ))}

            {underscoreTiles}
          </div>
    );
}
  
export default Guess3Rack;