import React, { useState, useEffect, useRef } from 'react';
import Guess3Rack from './guess3/Guess3Rack';
import wordlistJson from './includes/wordlist.json'; 
import HiddenTextInput from './HiddenTextInput'; 
import Share from './Share'; 

function Guess3() {
    const [i, setI] = useState<number>(0);
    const [word, setWord] = useState<string>('');
    const [hint, setHint] = useState<string>('');
    const [guess, setGuess] = useState<string>('');
    const [bingos, setBingos] = useState<string[]>([]);
    const [timeElapsed, setTimeElapsed] = useState<number>(0);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [shareMessage, setShareMessage] = useState<string>('');
    
    // focus on keyboard when module renders
    const inputRef = useRef<HTMLInputElement>(null);

    // store today's time in local storage
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const date = String(`${year}-${month}-${day}`);

    useEffect(() => {
         // Check if the user has completed for today in local storage
        const completionTime = localStorage.getItem("guess3:" + date);
        if (completionTime) {
            setIsComplete(true); // Set isComplete to true if completionTime is found
            setTimeElapsed(Number(completionTime)); // Set timeElapsed to the completion time from local storage
        } else {
            GetWord();
            
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, []);

    function GetWord(){
        const gameNumber = DayDifference();
        const newWord : string = wordlistJson[gameNumber * 3 + i].toUpperCase();
        
        setHint(newWord[0]);
        setWord(newWord);
        setGuess('');
    }

    useEffect(() => {
        if (hint + guess === word) {
            Bingo();
        }
    }, [guess, hint]);

    function Bingo() {
        if (i < 3) {
            setI(i+1);
            GetWord();
        } else {
            setWord('');
            setIsComplete(true);

            localStorage.setItem("guess3:"+date, timeElapsed.toString());
        }
        setBingos([...bingos, word]);
    }

    function DayDifference(): number {
        const compareDate = new Date('2024-03-21');
        const currentDate = new Date();
        const differenceInMilliseconds = currentDate.getTime() - compareDate.getTime();
        return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key.toUpperCase();

        if (/^[A-Z]$/.test(key)) {
            if (hint.length + guess.length < word.length) {
                setGuess(guess + key);
            }
        } else if (key === 'BACKSPACE') {
            setGuess(guess.slice(0, -1));
        }
    };

    useEffect(() => {
        const startTime = Date.now() - timeElapsed;
        let intervalSec: NodeJS.Timeout | null = null;
        if(!isComplete){
            intervalSec = setInterval(() => {
                setTimeElapsed(Date.now() - startTime);
            }, 1000); // Update every second
        } else{
            clearInterval(intervalSec!);
        }
        // Cleanup function to clear the interval when the component unmounts or when isComplete changes
        return () => {
            if (intervalSec) {
                clearInterval(intervalSec);
            }
        };
    }, [isComplete]);
    
    // Add new letter to hint every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if (hint.length < word.length) {
                // Add the next letter from the word to the hint
                setHint(prevHint => prevHint + word[hint.length]);
                setGuess(prevGuess => prevGuess.slice(1));
            } else {
                clearInterval(interval); // Stop the interval when all letters are added to the hint
            }
        }, 10000); // Update every 10 seconds
        return () => clearInterval(interval);
    }, [word, hint]);


    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const milliseconds = Math.floor((time % 1000) / 10);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const date = new Date();
        const d = date.toLocaleString('en-us', { month: 'short' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
        let copy = 'Guess 3: ' + d + '\n';
        copy += 'Guessed 3 Today In: ' + formatTime(timeElapsed) + '\n';
        copy += 'https://brainiac.games#guess3';
    
        setShareMessage(copy);
    }, [isComplete]);

    return (
        <div onClick={() => inputRef.current?.focus()} onKeyDown={handleKeyDown}>
            {
                localStorage.getItem("guess3:" + date) && bingos.length < 3 ? (
                    <div>Come back tomorrow for a new game.</div>
                ) : (
                    <fieldset style={{width: 'auto'}}>
                        <legend>{formatTime(timeElapsed)}</legend>
                        <HiddenTextInput inputRef={inputRef} />
                        {bingos.map((bingo, index) => (
                            bingo !== '' && 
                            <Guess3Rack key={index} hint={bingo} guess='' word={bingo}  />
                        ))}
                        {word.length > 0 && (
                            <Guess3Rack hint={hint} guess={guess} word={word}  />
                        )}
                    </fieldset>
                )
            }
            {isComplete && <div><strong>Guessed 3 Today In {formatTime(timeElapsed)}!</strong></div>}
            {isComplete && <Share shareTitle='Guess 3' shareMessage={shareMessage} />}
        </div>
    );
}
  
export default Guess3;