import './hiddenTextInput.css';

const HiddenTextInput = ({ inputRef} ) => {

    return (
        <div>
            <input type="text" ref={inputRef} id="input" className="hidden-input" />
        </div>
    );
}
  
export default HiddenTextInput;