import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './games/Home';
import DailyScramble from './games/DailyScramble';
import Guess3 from './games/Guess3';
import ScrabbleCopilot from './games/ScrabbleCopilot';
import WordRace from './games/WordRace';
import WordleCopilot from './games/WordleCopilot';
import NotFound from './games/NotFound';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  // const location = useLocation();
  // console.log('Current path:', location.hash);
  

  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/dailyscramble" element={<DailyScramble/>} />
        <Route path="/guess3" element={<Guess3/>} />
        <Route path="/scrabblecopilot" element={<ScrabbleCopilot/>} />
        <Route path="/wordrace" element={<WordRace/>} />
        <Route path="/wordlecopilot" element={<WordleCopilot/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </div>
  );
}

export default App;