import React, { useState, useEffect, useRef, useCallback, forwardRef } from 'react';
import Tile from '../Tile';
import HiddenTextInput from '../HiddenTextInput';


const WordBuilder = forwardRef(({ dictionary, letterValues }, ref) => {
    const [isActive, setIsActive] = useState(false);
    const [matches, setMatches] = useState([]);

    /*** WORD BUILDER ***/
    // focus on keyboard when module renders
    const inputRef = useRef(null);

    const [tiles, setTiles] = useState([]);

    const handleKeyDown = (event) => {
        const key = event.key.toUpperCase(); // Convert to uppercase for consistency
        if (/^[A-Z ]$/.test(key) && tiles.length < 8) { // Check if key pressed is a letter
            setTiles([...tiles, key]);
        } else if (key === 'BACKSPACE') {
            setTiles(tiles.slice(0, -1));
        } else if (key === 'ENTER') {
            checkWords();
        }
    };

    const checkWords = useCallback(() => {
        let rack = tiles.join('').toLowerCase();

        const newMatches = dictionary.filter(word => {
            if (word.length > rack.length) return false;
            const wordChars = word.split('');
            const rackChars = rack.split('');
            let blanks = rackChars.filter(char => char === ' '); // Extract blank tiles

            for (const char of wordChars) {
                const index = rackChars.indexOf(char);
                if (index !== -1) {
                    rackChars.splice(index, 1);
                } else if (blanks.length > 0) {
                    blanks.pop(); // Use a blank tile
                } else {
                    return false; // No match found for this character
                }
            }
            return true;
        });

        // Sort the new matches by wordValue in descending order
        newMatches.sort((a, b) => calculateWordValue(b) - calculateWordValue(a));

        setMatches(newMatches);
    }, [dictionary, tiles]);

    useEffect(() => {
        checkWords(); // Run checkWords after tiles has been updated
    }, [tiles, checkWords]);

    function calculateWordValue(word) {
        let rack = tiles.join('');
        const wordChars = word.toUpperCase().split('');
        let totalValue = 0;

        for (const char of wordChars) {
            const index = rack.indexOf(char);
            if (index !== -1) { // Check if the character is in the rack word
                totalValue += letterValues[char];
                rack = rack.slice(0, index) + rack.slice(index + 1); // Remove the character from rackWord
            }
        }

        return totalValue;
    }

    const open = () => {
        setIsActive(true);
        inputRef.current?.focus();
    };

    const close = () => {
        setIsActive(false);
    };

    return (
        <div tabIndex={0} onClick={() => inputRef.current?.focus()} onKeyDown={handleKeyDown}>
            <div className="container" onClick={isActive ? close : open}>
                Word Builder
                {isActive ?
                    <div className="plusminus">&#x2212;</div>
                    :
                    <div className="plusminus">&#x2b;</div>
                }
            </div>
            
            <div id='scrabble-content' className={isActive ? 'active' : ''}>
            
                <div>Enter your Scrabble letters (use space for blanks) to see possible words.</div>

                {tiles.map((tile, index) => (
                    <Tile key={index} letter={tile} value={letterValues[tile]} color='green' />
                ))}

                <HiddenTextInput inputRef={inputRef} />

                {matches.length > 0 ? (
                    <div style={{ margin: '10px auto', textAlign: 'center' }}>
                        {matches.map((word, index) => {
                            const wordValue = calculateWordValue(word);
                            let wordOut = '';

                            const wordChars = word.toUpperCase().split('');
                            let rack = tiles.join('');

                            for (const char of wordChars) {
                                const index = rack.indexOf(char);
                                if (index !== -1) { // Check if the character is in the rack word to cover blanks
                                    wordOut += char.toUpperCase();
                                    rack = rack.slice(0, index) + rack.slice(index + 1); // Remove the character from rackWord
                                } else { // used a blank
                                    wordOut += char.toLowerCase();
                                }
                            }

                            return (
                                <span key={index}>
                                    <strong>{wordOut}</strong>:{wordValue}
                                    {index !== matches.length - 1 && ', '}
                                </span>
                            );
                        })}
                    </div>
                ) : (
                    <div>
                        {isActive && <div>No words found.</div>}
                    </div>
                )}
            </div>
        </div>
    );
});

export default WordBuilder;
