const Home = () => {
    return (
      <div style={{textAlign: 'left'}}>
        <p>Welcome to Brainiac Games! Get ready for some mind-bending fun with our daily word games and Copilots. Whether you're a word wizard or just here for the challenge, we've got something for you.</p>

        <p>Sharpen your skills, master Scrabble and Wordle, challenge your friends, and vie for the title of Brainiac Champion. </p>

        <p>Join us daily at Brainiac Games for endless wordplay and excitement. Let the games begin!</p>
      </div>
    );
  };
  
  export default Home;