import React, { useState, useEffect, forwardRef } from 'react';

const WordLists = forwardRef(({ dictionary, letterValues }, ref) => {
    const [wordListTitle, setWordListTitle] = useState('Two Letter Words');
    const [words, setWords] = useState([]);
    const [isActive, setIsActive] = useState(false);

    // Call NewWordList when the component is rendered
    useEffect(() => {
        ShowWords(dictionary.filter(word => word.length === 2));
    }, [dictionary]);

    /*** WORD LISTS ***/
    // Function to handle change in select
    const NewWordList = (event) => {
        let wordList = [];

        switch (event.target.value) {
            case 'twoletter':
              setWordListTitle('Two Letter Words');
              wordList = dictionary.filter(word => word.length === 2);
              break;
            case 'threeletter':
                setWordListTitle('Three Letter Words');
                wordList = dictionary.filter(word => word.length === 3);
                break;
            case 'jWords':
                setWordListTitle('J Words (5 letters or less)');
                wordList = dictionary.filter(word => word.includes('j') && word.length < 6);
                break;
            case 'qWords':
                setWordListTitle('Q Words (5 letters or less)');
                wordList = dictionary.filter(word => word.includes('q') && word.length < 6);
                break;
            case 'xWords':
                setWordListTitle('X Words (5 letters or less)');
                wordList = dictionary.filter(word => word.includes('x') && word.length < 6);
                break;
            case 'zWords':
                setWordListTitle('Z Words (5 letters or less)');
                wordList = dictionary.filter(word => word.includes('z') && word.length < 6);
                break;
            default:
              setWordListTitle('');
              setWords([]);
              break;
          }

          ShowWords(wordList);
    };

    function ShowWords(wordList){
        let elements = [];

        wordList.sort();
          // Iterate through the sorted word list
          wordList.forEach((word, index) => {
            // Add word to the array
            elements.push(<span key={word}>{word}</span>);

            // Check if it's not the last word
            if (index < wordList.length - 1) {
            // Check if the first letter of the next word is different
            if (word[0] !== wordList[index + 1][0]) {
                elements.push(<p key={`p-${index}`} />);
            } else {
                // Add a space between words
                elements.push('  ');
            }
            }
          });
          setWords(elements);
    }

    const open = () => {
        setIsActive(true);
    };

    const close = () => {
        setIsActive(false);
    };


    return (
        <div>
            <div className="container" onClick={isActive ? close : open}>
                Word Lists
                {isActive ?
                    <div className="plusminus">&#x2212;</div>
                    :
                    <div className="plusminus">&#x2b;</div>
                }
            </div>
            
            
            <div id='scrabble-content' className={isActive ? 'active' : ''}>
                <select onChange={NewWordList} id="select-word-list">
                    <option value="twoletter">2 Letter Words</option>
                    <option value="threeletter">3 Letter Words</option>
                    <option value="jWords">J Words</option>
                    <option value="qWords">Q Words</option>
                    <option value="xWords">X Words</option>
                    <option value="zWords">Z Words</option>
                </select>
                <div style={{textAlign:'left'}}><strong>{wordListTitle}</strong><br />
                    {words}
                </div>
            </div>
        </div>
    );
});

export default WordLists;
