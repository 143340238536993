
function DailyScramble() {
    
    return (
        <div style={{ maxWidth: '370px', margin: '0 auto' }}>
            <iframe src="https://dailyscramble.co#dailyscramble" allow="clipboard-read; clipboard-write; web-share" width="370" height="750" />
        </div>
    );
  }
  
  export default DailyScramble;