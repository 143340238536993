import React, { useState, useEffect, useRef } from 'react';
import dictionaryJson from './includes/dictionary.json';
import Rack from './Rack';
import Tile from './Tile';
import HiddenTextInput from './HiddenTextInput';
import Share from './Share';

const WordRace = () => {
    const inputRef = useRef(null);
    const [legendValue, setLegendValue] = useState('Words: 0, Letters: 0');
    const [timerExpired, setTimerExpired] = useState(false);
    const [timer, setTimer] = useState(180); // Initial timer value
    const [tiles, setTiles] = useState([]);
    const [newTiles, setNewTiles] = useState([]);
    const [dictionary, setDictionary] = useState([]);
    const [words, setWords] = useState([]);
    const [shareMessage, setShareMessage] = useState('');

    // Focus on input when component mounts
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    // Timer functionality
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer > 0) {
                    return prevTimer - 1; // Decrement timer by 1 every second
                } else {
                    setTimerExpired(true); // Set timerExpired to true when timer reaches 0
                    clearInterval(interval); // Stop the timer interval
                    return 0; // Ensure timer is 0
                }
            });
        }, 1000);
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []); 

    useEffect(() => {
        const date = new Date();
        const d = date.toLocaleString('en-us', { month: 'short' }) + ' ' + date.getDate() + ', ' + date.getFullYear();
        let copy = 'Word Race: ' + d + '\n';
        copy += legendValue + '\n';
        copy += 'https://brainiac.games#wordrace';
    
        setShareMessage(copy);
    }, [legendValue]);


    // Format time for display
    const formatTime = (time) => {
        const mins = Math.floor(time / 60);
        const secs = time % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    // Handle key down events
    const handleKeyDown = (event) => {
        const key = event.key.toUpperCase();
        setMessage('');

        if (!timerExpired) {
            if (key === 'ENTER') {
                checkWord();
            } else if (key === 'BACKSPACE') {
                setNewTiles(prevTiles => prevTiles.slice(0, -1)); // Remove last tile
            } else if (tiles.find(tile => tile.letter === key)) {
                setNewTiles(prevTiles => [...prevTiles, { letter: key, points: -1 }]);
            }
        }
    };

    useEffect(() => {
        // Set the dictionary state with the imported JSON data
        setDictionary(dictionaryJson);

        // Cleanup function to clear any resources
        return () => {
            // Perform cleanup tasks here (if any)
        };
      }, []); 

    const [message, setMessage] = useState();

    const checkWord = () => {
        
        let word = newTiles.reduce((acc, curr) => acc + curr.letter, '');
        
        if (words.includes(word)) {
            setMessage(word + ' was already used');
        } else if (dictionary.includes(word.toLowerCase())) {
            words.push(word);
            setLegendValue('Words: ' + words.length + ', Letters: ' + words.reduce((total, word) => total + word.length, 0));
            setNewTiles([]);
        } else {
            setMessage(word + ' is not a word');
        }
    }

    return (
        <div onClick={() => inputRef.current.focus()} onKeyDown={handleKeyDown}>
            <HiddenTextInput inputRef={inputRef} />
            <Rack legend={legendValue} onTilesLoad={setTiles} />
            <fieldset className="active-fieldset">
                <legend id="timer">{timerExpired ? 'TIME EXPIRED' : `Timer: ${formatTime(timer)}`}</legend>
                <div id="build" className="tilerack">
                    {newTiles.map((tile, index) => (
                        <Tile key={index} letter={tile.letter} color="green" />
                    ))}
                </div>
            </fieldset>
            {timerExpired && <Share shareTitle='Word Race' shareMessage={shareMessage} />}
            <div style={{ fontWeight:'bold' }}>{message}</div>
            <div>{ words.sort().join(', ') }</div>
        </div>
    );
};

export default WordRace;
