import React, { useEffect, useState } from 'react';
import './rack.css';
import Tile from './Tile.js';

const Rack = ({legend, onTilesLoad}) => {
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
      const loadRackJson = async () => {
        try {
          const hash = window.location.hash.substr(1); // Remove the "#" character
          const rackData = await import(`./${hash}/rack.json`);

          // Get today's date in the format "YYYY-MM-DD"
          const today = new Date().toISOString().split('T')[0];

          // Check if rack data exists for today's date
          if (rackData[today]) {
            const rackTiles = Object.entries(rackData[today]).map(([letter, { points }]) => ({
              letter,
              points
            }));
            setTiles(rackTiles);
            onTilesLoad(rackTiles); // Pass tiles up to parent component
          } else {
            console.error(`No rack data found for ${today}`);
          }
        } catch (error) {
          console.error('Error loading rack.json:', error);
        }
      };

      loadRackJson();
    }, []); // Run only once on component mount

    // Function to shuffle tiles
    const shuffleTiles = () => {
      // Create a copy of the tiles array
      const shuffledTiles = [...tiles];
      // Shuffle the copy of tiles array
      shuffledTiles.sort(() => Math.random() - 0.5);
      // Update the state with shuffled tiles
      setTiles(shuffledTiles);
    };

    return (
      <fieldset>
        <legend onClick={shuffleTiles} className="rack-legend">{legend}</legend>
        <div id="rack">
          {tiles.map((tile, index) => (
            <Tile key={index} letter={tile.letter} value={tile.points} />
          ))}
        </div>
      </fieldset>
    );
}
  
export default Rack;