import React, { useState, useEffect, useRef } from 'react';

const ScoreKeeper = () => {
    const [isActive, setIsActive] = useState(false);
    const [players, setPlayers] = useState(JSON.parse(localStorage.getItem('ScoreKeeper-Players')) || []);
    const [scores, setScores] = useState(JSON.parse(localStorage.getItem('ScoreKeeper-Scores')) || []);
    const [focusedInput, setFocusedInput] = useState(null);
    const inputRefs = useRef([]);

    const open = () => {
        setIsActive(true);
    };

    const close = () => {
        setIsActive(false);
    };

    useEffect(() => {
        // Load players and scores from localStorage
        console.log('ScoreKeeper:' + localStorage.getItem('ScoreKeeper-Players') + ' ' + localStorage.getItem('ScoreKeeper-Scores'));
        const storedPlayers = JSON.parse(localStorage.getItem('ScoreKeeper-Players'));
        const storedScores = JSON.parse(localStorage.getItem('ScoreKeeper-Scores'));
        if (storedPlayers && storedScores) {
            setPlayers(storedPlayers);
            setScores(storedScores);
        }
    }, []);

    useEffect(() => {
        // Store players in localStorage
        localStorage.setItem('ScoreKeeper-Players', JSON.stringify(players));
        localStorage.setItem('ScoreKeeper-Scores', JSON.stringify(scores));
    }, [players, scores]);

    const handleScoreBlur = (event, playerIndex, scoreIndex) => {
        const inputValue = event.target.value.trim(); // Trim whitespace
        if (inputValue !== '') {
            const newScore = parseInt(inputValue);
            if (newScore >= 0) {
                const updatedScores = [...scores];
                updatedScores[playerIndex][scoreIndex] = newScore;
                setScores(updatedScores);
                event.target.value = '';
            }  else {
                alert('New score must be greater than or equal to 0');
            }
        }
        setFocusedInput(null);
    };

    const handleFocus = (index) => {
        setFocusedInput(index);
        inputRefs.current[index].focus(); // Focus on the input field
    };

    const addPlayer = () => {
        const playerName = window.prompt('Enter player name:');
        if (playerName) {
            const newPlayers = [...players, playerName];
            const newScores = [...scores, []];
            setPlayers(newPlayers);
            setScores(newScores);
        }
    };

    const restartGame = () => {
        const confirmRestart = window.confirm('Are you sure you want to start a new game?');
        if (confirmRestart) {
            setPlayers([]);
            setScores([]);
            localStorage.removeItem('ScoreKeeper-Players');
            localStorage.removeItem('ScoreKeeper-Scores');
        }
    };

    const handleScoreClick = (playerIndex, scoreIndex) => {
        const currentScore = scores[playerIndex][scoreIndex];
        let newScore = window.prompt('Update (or remove) a score.', currentScore);
        if (newScore === null) { return; } // User clicked cancel, do nothing
        if (newScore === '') {
            // If newScore is empty string, delete the score
            const updatedScores = [...scores];
            updatedScores[playerIndex].splice(scoreIndex, 1); // Remove the score at scoreIndex
            setScores(updatedScores);
        } else {
            newScore = parseInt(newScore);
            if (!isNaN(newScore) && newScore >= 0) {
                const updatedScores = [...scores];
                updatedScores[playerIndex][scoreIndex] = newScore;
                setScores(updatedScores);
            } else {
                alert('New score must be a positive number');
            }
        }
    };

    // focus on the next input when Tab is pressed
    const handleKeyDown = (event, index) => {
        if (event.key === 'Tab' || event.key === 'Enter') {
            event.preventDefault(); // Prevent default Tab behavior
            const nextIndex = index === players.length - 1 ? 0 : index + 1; // Get the index of the next input
            setFocusedInput(nextIndex); // Set focus to the next input
            inputRefs.current[nextIndex].focus();
        }
    };
    
    

    return (
        <div>
            <div className="container" onClick={isActive ? close : open}>
                Score Keeper
                {isActive ?
                    <div className="plusminus">&#x2212;</div>
                    :
                    <div className="plusminus">&#x2b;</div>
                }
            </div>
            
            <div id='scrabble-content' className={isActive ? 'active' : ''}>
                <div>   
                    <span onClick={addPlayer} style={{color: 'darkgreen', fontWeight:'bold', cursor:'pointer'}}>
                        Add Player &nbsp; <i className="fa fa-plus-square" aria-hidden="true"></i>
                    </span>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', minHeight:'200px' }}>
                    {players.map((player, playerIndex) => (
                        <div key={playerIndex} style={{ flex: '1 0 25%', padding: '10px' }}>
                            <h5 style={{borderBottom:'solid #000 1px', color:'#000'}}><strong>{player}</strong></h5>
                            
                            {scores[playerIndex].map((score, scoreIndex) => {
                                const runningTotal = scores[playerIndex].slice(0, scoreIndex + 1).reduce((acc, cur) => acc + cur, 0);
                                return (
                                    <div style={{ fontFamily: 'monospace', color:'#700' }}>
                                        <div>
                                            {scoreIndex === 0 ? (
                                                <div>
                                                    <span onClick={() => handleScoreClick(playerIndex, scoreIndex)} style={{ cursor: 'pointer' }}>
                                                        {String.fromCharCode(160).repeat(4 - String(score).length) + score}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div>
                                                        <span onClick={() => handleScoreClick(playerIndex, scoreIndex)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                                            {'+' + String.fromCharCode(160).repeat(3 - String(score).length) + score}
                                                        </span>
                                                    </div>
                                                    <div style={{fontWeight:'bold'}}>
                                                        {String.fromCharCode(160).repeat(4 - String(runningTotal).length) + runningTotal}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}

                        <div>
                            <input
                                ref={(el) => (inputRefs.current[playerIndex] = el)} // Create a ref for each input field
                                type="number"
                                style={{
                                    width: '50px',
                                    border: focusedInput === playerIndex ? '2px solid blue' : '1px solid #ccc',
                                    backgroundColor: focusedInput === playerIndex ? '#eee   ' : 'white'
                                }}
                                onFocus={() => handleFocus(playerIndex)}
                                onBlur={(e) => handleScoreBlur(e, playerIndex, scores[playerIndex].length)}
                                onKeyDown={(e) => handleKeyDown(e, playerIndex)}
                            />
                        </div>
                        </div>
                    ))}

                </div>
                <div style={{margin:'20px'}}>
                    <span onClick={restartGame} style={{color:'#f00', fontWeight:'bold', cursor:'pointer'}}>
                        Restart Game &nbsp;
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ScoreKeeper;