import React, { useState, useRef, forwardRef } from 'react';
import Tile from '../Tile';
import HiddenTextInput from '../HiddenTextInput';


const AddOneLetter = forwardRef(({ dictionary, letterValues }, ref) => {
    const [isActive, setIsActive] = useState(false);
    const [enterPressed, setEnterPressed] = useState(false);
    const [word, setWord] = useState('');
    const [matches, setMatches] = useState('');

    // focus on keyboard when module renders
    const inputRef = useRef(null);

    const [tiles, setTiles] = useState([]);

    const handleKeyDown = (event) => {
        const key = event.key.toUpperCase(); // Convert to uppercase for consistency
        if (/^[A-Z ]$/.test(key) && tiles.length < 8) { // Check if key pressed is a letter
            setEnterPressed(false);
            setTiles([...tiles, key]);
        } else if (key === 'BACKSPACE') {
            setEnterPressed(false);
            setTiles(tiles.slice(0, -1));
        } else if (key === 'ENTER') {
            setEnterPressed(true);
            checkWord();
        }
    };

    function checkWord() {
        let rack = tiles.join('').toLowerCase()
        setWord(rack);

        const newMatches = dictionary.filter(word => word.length === rack.length + 1 && word.includes(rack));

        setMatches(newMatches);
    }

    const open = () => {
        setIsActive(true);
        inputRef.current?.focus();
    };

    const close = () => {
        setIsActive(false);
    };

    return (
        <div onClick={() => inputRef.current?.focus()} onKeyDown={handleKeyDown}>
            <div className="container" onClick={isActive ? close : open}>
                Add One Letter
                {isActive ?
                    <div className="plusminus">&#x2212;</div>
                    :
                    <div className="plusminus">&#x2b;</div>
                }
            </div>
            
            <div id='scrabble-content' className={isActive ? 'active' : ''}>
            <div>Type a word and press enter to check if a letter can be added to it.</div>

            {tiles.map((tile, index) => (
                <Tile key={index} letter={tile} value={letterValues[tile]} color='green' />
            ))}

            <HiddenTextInput inputRef={inputRef} />

            {enterPressed &&
                (matches.length > 0 ? (
                    matches.map((match, index) => {
                        if (match.startsWith(word)){
                            return (<span key={index}>{word}<strong>{match[match.length-1]}</strong> </span>);;
                        } else {
                            return (<span key={index}><strong>{match[0]}</strong>{word} </span>);
                        }    
                    })
                ) : (
                    <div>No words found</div>
                ))
            }
            </div>
        </div>
    );
});

export default AddOneLetter;
